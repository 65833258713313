import { useStaticQuery, graphql } from "gatsby"

const useBlogPosts = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
          filter: {fileAbsolutePath: {regex: "/blog/"}}
          sort: {order: DESC, fields: frontmatter___date}
        ) {
        nodes {
          frontmatter {
            slug
            title
            seoTitle
            date
            metaDesc
            category
            featuredImage {
              sharp: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  
  return data.allMdx.nodes.map(blogPost => ({
    title: blogPost.frontmatter.title,
    slug: blogPost.frontmatter.slug,
    featuredImage: blogPost?.frontmatter?.featuredImage,
    seoTitle: blogPost.frontmatter.seoTitle,
    date: blogPost.frontmatter.date,
    metaDesc: blogPost.frontmatter.metaDesc,
    category: blogPost.frontmatter.category,
  }))
}

export default useBlogPosts